$color1 : #F8F0F0;
$color2 : #ffa000;
$color3 : #28282BA6;
$color4 : #424242;
$color5 : #FFEE97;
$color6 : #C8E6C9;
$color7 : #BBDEFB;
$greencolor1 : #20370A;
$greencolor2 : #AAE875;
$greencolor3 : #AEE57F;
$greencolor4 : #4CAF50;
$purplecolor1 :#6F6C90;
$backgroundColor: #FAF9F6;
$greycolor1: #E4E7EC;
$greycolor2: #F7F7FF;
$bluecolor: #4A3AFF;

// common css

.MarginTop {
    margin-top: 100px;
}

.Primarybtn {
    background-color: $greencolor2;
    padding: 12px 24px;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    border: none;
    font-size: 18px;
    color: $greencolor1;

    &:hover {
        background-color: #fff;
        border: 1px solid $greencolor1;
    }
}

.button2 {
    background-color: $color2;
    padding: 12px 24px;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    border: none;
    font-size: 18px;
    color: #fff;

    &:hover {
        background-color: #fff;
        border: 1px solid $color2;
        color: $color2;
    }
}

.heading {
    text-align: center;

    h2 {
        font-size: 36px;
        font-weight: 700;
        color: $greencolor1;
    }

    h5 {
        color: $greencolor1;
        font-size: 24px;
        font-weight: 400;

    }
}

.commonAccordion {
    .accordion {
        .accordion-item {
            border-radius: 15px;

            .accordion-header {
                .accordion-button {
                    background-color: #fff;
                    border-radius: 15px;

                    font-size: 22px;
                    font-weight: 500;
                    line-height: 28px;
                    color: $greencolor1;

                    &:focus,
                    &:not(.collapsed) {
                        box-shadow: none;
                    }

                    &::after {
                        content: '';
                        background-color: $greycolor2;
                        border-radius: 8px;
                        background-position: center;
                        width: 40px;
                        height: 40px;
                        background-image: url('../Assets/PlusSign.png');

                    }

                    &:not(.collapsed)::after {
                        background-image: url('../Assets/minusSign.png');
                        background-color: $bluecolor;
                    }
                }
            }

            .accordion-collapse {
                .accordion-body {
                    color: $purplecolor1;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

        }
    }
}

// custom css 

.HomePage-banner-section {
    background-color: $color1;
    padding: 50px 0px;

    .text {
        h1 {
            font-weight: 700;
            font-size: 48px;
            line-height: 84px;
            color: $greencolor1;

            span {
                color: $color2;
            }
        }

        h5 {
            margin: 40px 0px;
            font-size: 22px;
            font-weight: 400;
            line-height: 33px;
            color: $color3;

        }
    }
}

.whatweOffer-section {
    background-color: $backgroundColor;
    padding: 60px 0px 70px 0px;
    position: relative;

    .wwo-card {
        border-radius: 20px;
        box-shadow: 100px 100px 50px 0px #0000000D;
        text-align: center;
        padding: 50px 30px;
        margin-top: 40px;
        height: 230px;
        transition: box-shadow .3s ease-in;

        h4 {
            font-size: 24px;
            font-weight: 500;
            color: #000;
        }

        h6 {
            color: $color4;
            font-size: 18px;
            font-weight: 400;
        }

        &:hover {
            // box-shadow: 3px 3px 3px 3px $color2;
            box-shadow: 5px 5px 0px 3px #FFA000;
        }
    }

    &.Left-side-Image {

        background-image: url('../Assets/whatweoffer-section-image.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 20%;
        width: 100%;
        height: 40%;
    }
}

.coreFeatures-section {
    background-color: $backgroundColor;

    .coreFeatures {
        border-radius: 60px;
        background-color: $greencolor3;
        padding: 50px 0px;

        .text {
            margin-top: 20px;

            .content {
                padding: 20px 0px;
                display: flex;
                align-items: center;

                .circle {
                    margin-right: 20px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #fff;
                    flex-shrink: 0;
                }

                h5 {
                    font-size: 24px;
                    font-weight: 400;
                    color: $greencolor1;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.LoanSteps-section {
    padding: 100px 0px;
    background-color: $backgroundColor;

    .box {
        padding: 50px 60px;
        margin-top: 50px;
        border-radius: 45px;
        background-color: #fff;
        box-shadow: 11.52px 11.52px 34.57px 0px #0202460D;

        .text {
            display: flex;

            .Left-side {
                margin-right: 20px;

                .circle {
                    flex-shrink: 0;
                    width: 35px;
                    height: 35px;
                    background-color: $greencolor4;
                    border-radius: 50%;

                    &.active {
                        box-shadow: 0px 0px 0px 5.76px #F2F4F7;
                        background-color: #fff;
                        border: 10px solid $greencolor4;
                    }
                }

                .Line {
                    width: 3px;
                    height: 45px;
                    border-radius: 3px;
                    background-color: $greycolor1;
                    margin: 4px auto;
                }
            }

            .Right-side {
                h5 {
                    color: $greencolor1;
                    font-size: 20px;
                    font-weight: 500;
                }

                h6 {
                    color: $purplecolor1;
                    font-size: 20px;
                    font-weight: 400;

                }
            }
        }
    }
}

.Eligibility-FAQ-section {
    background-color: $backgroundColor;
    padding: 0px 0px 50px 0px;

    .outerbox {
        padding: 20px;
    }

    &.background-image {
        background-image: url('../Assets/EligibilitysectionImage.png');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: 20%;
        width: 100%;
        height: 40%;
    }

    .background-image-second {
        background-image: url('../Assets/FaqEligibilityImage.png');
        background-position: center left;
        background-repeat: no-repeat;
        background-size: 25%;
        width: 100%;
        height: 40%;
    }

    .box {
        box-shadow: 5px 5px 5px -3px $color4;
        position: relative;
        border-radius: 40px;
        padding: 30px 40px;
        margin-top: 20px;
        min-height: 420px;

        h3 {
            color: $greencolor1;
            font-size: 35px;
            font-weight: 500;

        }

        ul {
            list-style-type: none;
            padding-left: 0px;
            margin: 50px 0px;

            li {
                font-size: 20px;
                margin: 20px 0px;
                font-weight: 400;
                color: $greencolor1;
            }
        }
    }

    .faqBox {
        padding: 100px 40px 250px 40px;

        &.Faq-background-image {
            background-image: url('../Assets/faqimage.png');
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: 20%;
            width: 100%;
            height: 40%;
        }
    }
}

.EMIcalculator {
    background-color: $backgroundColor;
    padding: 50px 0px;

    .inputgrp {
        width: 75%;
        border-bottom: 2px solid $greencolor1;
        padding-bottom: 10px;

        .input-label {
            color: $greencolor1;
            font-size: 24px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
        }

        .inputsubgrp {
            display: flex;
            margin-top: 10px;

            span {
                font-size: 32px;
                font-weight: 700;
                line-height: 16px;
                text-align: left;
                align-self: center;
                color: $greencolor1;
            }

            .input-tag {
                background-color: transparent;
                border: none;
                font-size: 32px;
                width: 100%;
                font-weight: 700;
                color: $greencolor1;

                &:focus-visible {
                    outline: none;
                }
            }

            .tab1 {
                align-self: center;
                border-radius: 25px;
                padding: 10px 15px;
                background-color: transparent;

                h5 {
                    color: $greencolor1 !important;
                    margin-bottom: 0px;
                }

                &.active {
                    background-color: $greencolor3;

                    h5 {
                        color: $greencolor1 !important;
                    }
                }
            }
        }
    }

    .amount-box {
        background-color: $greencolor4;
        color: #fff;
        padding: 30px 25px;
        border-radius: 17px;

        h6 {
            margin-bottom: 25px;
            font-size: 32.93px;
            font-weight: 700;
            line-height: 14.64px;
            text-align: left;

            span {

                font-size: 14.64px;
                font-weight: 400;
                line-height: 14.64px;

            }
        }

        .hrtag {
            border: 1.83px solid #FFFFFF
        }

        .btns {
            .ApplyBtns {
                display: block;
                width: 100%;
                text-align: center;
                background-color: #fff;
                color: $greencolor1;
                font-weight: 600;
                padding: 10px 40px;
                border-radius: 45px;
                font-size: 21.77px;
                text-decoration: none;

                &:hover {
                    background-color: $greencolor2;
                    border: 2px solid $greencolor1;
                }
            }
        }
    }

}

.LendingPartner-section {
    padding: 60px 0px;
    background-color: $backgroundColor;

    .box {
        margin-top: 40px;
        padding: 0px 150px;

        .subbox {
            padding: 40px 50px;
            border-radius: 12px;
            background-color: $greencolor3;
            text-align: center;

            a {
                color: $greencolor1;
                font-size: 24px;
                font-weight: 600;
            }

            h6 {
                margin-top: 20px;
                font-size: 24px;
                font-weight: 400;
            }
        }
    }

}

.Policy {
    padding: 40px 0px;

    h2 {
        font-size: 30px;
        font-weight: 600;
        color: $greencolor1;
        margin-bottom: 30px;
    }

    h5 {
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: 400;
        color: $greencolor1;

        a {
            font-weight: 500;
            font-size: 18px;
        }
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        color: $greencolor1;
    }

    ul {
        list-style-type: none;
        padding-left: 20px;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        color: $greencolor1;
    }
}

.Aboutus-Page {
    .aboutus-banner {
        background-color: $greencolor3;
        padding: 30px 0px;

        .text {
            padding: 65px 0px;

            h1 {
                color: $color3;
                font-size: 50px;
                font-weight: bold;

                span {
                    color: $color2;
                }
            }

            h5 {
                color: $color3;
                font-size: 22px;
                font-weight: 400;
                margin: 35px 0px;
            }
        }
    }

    .MissionVision-section {
        background-color: $backgroundColor;
        padding: 80px 0px;

        .missioncols {
            padding: 30px 60px;

            .content {
                h2 {
                    color: $greencolor1;
                    font-size: 80px;
                    font-weight: 500;
                }

                h5 {
                    margin: 30px 0px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    color: $color4;
                }

                .imagebox {
                    padding: 10px;
                    background-color: $backgroundColor;
                    border-radius: 14px;
                    box-shadow: -1px 30px 48px 0px rgba(0, 0, 0, .2);

                }
            }
        }

        .valuescontent {
            h2 {
                color: $greencolor1;
            }
        }
    }

    .whoweare-section {
        padding-top: 60px;
        background-color: $backgroundColor;

        .box {
            padding: 60px;
            position: relative;

            .subbox {
                background-color: $greencolor3;
                border-radius: 45px;
                padding: 40px 50px;

                h3 {
                    font-size: 80px;
                    color: $greencolor1;
                    font-weight: 500;
                }

                h6 {
                    color: $greencolor1;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 28px;
                    margin: 30px 0px 70px 0px;
                }

                .image {
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    .joinTeam-section {
        padding: 70px 0px 60px 0px;
        background-color: $backgroundColor;

        .box {
            position: relative;

            .subbox {
                position: absolute;
                padding: 40px 300px;
                text-align: center;

                h3 {
                    color: $greencolor1;
                    font-size: 80px;
                    font-weight: 500;
                    margin-bottom: 25px;
                }

                h5 {
                    font-size: 24px;
                    font-weight: 400;
                    color: $greencolor1;
                    margin-bottom: 40px;
                }
            }

        }
    }

    .Joinus-sectionTop{
        background-color: $backgroundColor;
    .Joinus {
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 80px 0px 0px 0px;

        &::before {
            background-color: $color7;
            border-radius: 50%;
            content: "";
            width: 350px;
            height: 350px;
            position: absolute;
            right: -140px;
            top: 500px;
            z-index: 1;
            opacity: 0.8;
        }

        .circle {
            position: absolute;
            top: 10px;
            opacity: 0.8;
            z-index: 0;
            margin: 0px auto;
            background-color: $color5;
            border-radius: 50%;
            width: 1160px;
            height: 1080px;
        }

        .circle-left {
            position: absolute;
            left: -10%;
            top: 80%;
            opacity: 0.8;
            z-index: 0;
            background-color: $color6;
            border-radius: 50%;
            width: 300px;
            height: 300px;
        }

        .text {
            position: relative;
            z-index: 10;
            padding: 12% 150px;
            text-align: center;

            h3 {
                font-size: 80px;
                color: $greencolor1;
                font-weight: 500;
                margin-bottom: 50px;
            }

            h5 {
                font-size: 24px;
                font-weight: 400;
                color: $greencolor1;
            }
        }
    }
}
}

.SupportPage {
margin: 40px 0px;

    .modal {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);

        .Activebtn2:hover {
            border: 0px ;
            background-color: $color2 ;
            color: white ;
        }

        .Activebtn:hover {
            border: 1px solid $color2 ;
            background-color: #fff ;
            color: $color2 ;
        }
    }

    .RequestOTPbtn{
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        border-radius: 10.7px;
        background-color: $color2;
        box-shadow: 0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15);
        padding: 15px 35px;
        border: 0px;
        &.inactive{
            padding: 15px 35px;
            border: 0px;
            background-color: rgba(255, 160, 0, .5);
            cursor: no-drop;
        }
        &.active:hover{
            border: 1px solid $color2;
            background-color: #fff;
            color: $color2;
        }
    }
    .RequestOTPbtn2{
        color: $color2;
        font-weight: 700;
        font-size: 14px;
        border-radius: 10.7px;
        background-color: #fff;
        box-shadow: 0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15);
        padding: 15px 25px;
        border: 1px solid $color2;
       
        &.active:hover{
            border: 1px solid $color2;
            background-color: $color2;
            color: #fff;
        }
    }

    h5 {
        font-size: 22px;
        font-weight: 600;
    }

    .Activebtn:hover {
        border: 1px solid $color2 ;
        background-color: #fff;
        color: $color2;
    }

    .Activebtn2:hover {
        border: 0px;
        background-color: $color2;
        color: white;
    }

    div.otpInputGroup {
        display: flex;
        justify-content: space-between;

        input.otpDigit {
            padding: 10px;
            text-align: center;
            appearance: textfield;
            width: 75px;
            height: 48px;
            border: none;
            border-bottom: 2px solid black;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .heads1 {
        border-radius: 6px;
        border: 1px solid rgba(255, 163, 35, .4);

        h6 {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            margin-top: 3px;
            margin-bottom: 0px;
        }

    }

    .formbox {
        border: 1px solid $greencolor1;
        border-radius: 12px;

        hr {
            border: .1px solid $greencolor1;
        }

    }

}


@media screen and (max-width:1200px) {
    .whatweOffer-section {
        .wwo-card {
            height: 300px;
        }
    }
}

@media screen and (max-width:1200px) and (min-width:992px) {
    .Aboutus-Page {
        .whoweare-section {
            .box {
                padding: 60px 0px;

                .subbox {

                    h6 {
                        font-size: 20px;
                        margin: 30px 0px 30px 0px;
                    }

                    .image {
                        img {
                            width: 80%;
                        }
                    }
                }
            }
        }

        .joinTeam-section {
            .box {
                .subbox {
                    position: absolute;
                    padding: 40px 150px;
                    top: -60px;

                    h3 {

                        font-size: 60px;
                    }

                    h5 {
                        font-size: 20px;
                    }
                }

            }
        }

        .Joinus-sectionTop{
        .Joinus {
            &::before {
                right: -180px;
                top: 550px;
            }

            .circle {
                width: 900px;
                height: 900px;
            }

            .circle-left {
                left: -12%;
            }

        }
        }
    }
}

@media screen and (max-width:991px) {
    .LoanSteps-section {
        #LoanStepsText {
            order: 2;
        }

        #LoanStepsImage {
            order: 1;
        }
    }

    .LendingPartner-section {
        .box {
            padding: 0px;

        }
    }

    .coreFeatures-section {
        #CoreFeatures1 {
            order: 2;
        }

        #CoreFeatures2 {
            order: 1;
        }
    }
    
    .Aboutus-Page {
        .whoweare-section {
            .box {
                padding: 60px 0px;

                .subbox {

                    #whoweare1 {
                        order: 2;
                    }

                    #whoweare2 {
                        order: 1;
                    }

                    h6 {
                        font-size: 20px;
                        margin: 30px 0px 30px 0px;
                    }

                    .image {
                        position: unset;

                        img {
                            width: 40%;
                        }
                    }
                }
            }
        }

        .joinTeam-section {
            .box {
                .subbox {
                    position: unset;
                    padding: 40px 60px;

                    h3 {

                        font-size: 60px;
                    }

                    h5 {
                        font-size: 20px;
                    }
                }

                .image {
                    display: none;
                }

            }
        }
        .Joinus-sectionTop{
        .Joinus {
            padding: 80px 0px 0px 0px;

            &::before {
                width: 350px;
                height: 350px;
                right: -180px;
                top: 550px;
            }

            .circle {
                top: 60px;
                width: 768px;
                height: 1000px;
            }

            .circle-left {
                left: -15%;
                top: 85%;
                width: 300px;
                height: 300px;
            }

        }
    }
}
}

@media screen and (max-width:576px) {
    .whatweOffer-section.Left-side-Image {
        background-size: 70%;
    }

    .LoanSteps-section {
        .box {
            padding: 50px 12px;

            .numbered-list {
                counter-reset: step;

                .text {
                    counter-increment: step;
                    margin-left: 1em;
                    position: relative;
                    margin-top: 20px;

                    .Left-side {
                        display: none;
                    }

                    &::before {
                        content: counter(step) ". ";
                        position: absolute;
                        left: -1em;
                        top: -2.5px;
                        font-size: 20px;
                        font-weight: 500;
                    }

                }
            }
        }
    }

    .Eligibility-FAQ-section {
        .outerbox {
            padding: 0px;
        }

        .box {
            padding: 30px 12px;
        }

        &.background-image {
            background-size: 50%;
        }

        .background-image-second {

            background-size: 50%;

        }

        .faqBox {
            padding: 100px 0px 120px 0px;

            &.Faq-background-image {

                background-size: 40%;
            }
        }
    }


    

    .EMIcalculator {
        padding: 12px;

        .inputgrp {
            width: 100%;
        }
    }

    .LendingPartner-section {
        .box {
            .subbox {
                padding: 40px 6px;
            }
        }
    }

    .Aboutus-Page {
        .MissionVision-section {
            .missioncols {
                padding: 0px;

                .content {
                    h2 {
                        font-size: 70px;
                    }
                }
            }
        }

        .whoweare-section {
            .box {
                .subbox {
                    padding: 40px 12px;

                    h3 {
                        font-size: 70px;
                    }

                    h6 {
                        font-size: 18px;

                    }

                    .image {
                        position: unset;

                        img {
                            width: 60%;
                        }
                    }
                }
            }
        }

        .joinTeam-section {
            .box {
                .subbox {
                    position: unset;
                    padding: 40px 0px;

                    h3 {

                        font-size: 60px;
                    }

                    h5 {
                        font-size: 20px;
                    }
                }

                .image {
                    display: none;
                }

            }
        }

        .Joinus-sectionTop{
        .Joinus {
            &::before {
                content: none;
            }

            .circle-left {
                display: none;
            }

            .text {
                position: relative;
                z-index: 10;
                padding: 30px 0px;
                text-align: center;

                h3 {
                    font-size: 60px;
                }

                h5 {
                    font-size: 20px;
                }
            }
        }
    }

    }

    .SupportPage {
     div.otpInputGroup {
            .otpDigit {
                width: 45px !important;
            }
        }
    }
}


// Animations 

// first Animation 
@keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
  
    75% {
      transform: translate3d(10px, 0, 0);
    }
  
    90% {
      transform: translate3d(-5px, 0, 0);
    }
  
    100% {
      transform: none;
    }
  }
  
  .bounceInRight {
    animation-name: bounceInRight;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  

  // second Animation 

  @keyframes slideUpAppear {
    from {
      transform: translateY(-200px);
      opacity: 0;
    }
    to {
      transform: translateY(-450px);
      opacity: 1;
    }
  }
  
  .slideUpAppear {
    animation-name: slideUpAppear;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

//   third animation 

@keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .ZoomIn {
    animation: zoomIn 1s ease both; 
  }

//   4th animation 

@keyframes moveLeftToRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .move-left-to-right {
    animation: moveLeftToRight 1s ease-in-out; 
  }

//   5th animation 

@keyframes moveRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .move-right-to-left {
    animation: moveRightToLeft 1s ease-in-out ; 
  }

//   6th animation 


@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .BottomSlideIn{
    animation: slideInFromBottom 1.5s ease-out forwards;
  }